import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as IconDashboard } from 'styles/images/Icons/dashboard.svg';
import LogoTera from 'styles/images/Icons/logo-tera.png';

import {
  AcademicCapOutlined,
  BuildingOffice2Outlined,
  BuildingStorefrontOutlined,
  DocumentCheckOutlined,
  ShieldCheckOutlined,
  SquaresPlusOutlined,
  UserOutlined,
  WalletOutlined,
} from 'tera-dls';

interface MenuProps {
  type: string;
  isCancel: boolean;
  onClickItemMenu?: () => void;
}

const Menu = ({ type, isCancel, onClickItemMenu }: MenuProps) => {
  const location = useLocation();
  // const navigate = useNavigate();

  const MENU = [
    {
      key: 'dashboard',
      url: '/dashboard',
      name: 'Dashboard',
      icon: IconDashboard,
      sub_menu: null,
    },
    {
      key: 'user',
      url: '/user/information',
      name: 'Thông tin cá nhân',
      icon: UserOutlined,
    },
    ...(['owner', 'individual'].includes(type) && !isCancel
      ? [
          {
            key: 'business',
            url: '/business/information',
            name: 'Thông tin doanh nghiệp',
            icon: BuildingOffice2Outlined,
          },
        ]
      : []),
    ...(['owner', 'individual'].includes(type) && !isCancel
      ? [
          {
            key: 'employee',
            url: '/employee/information',
            name: 'Thành viên',
            icon: UserOutlined,
            sub_menu: null,
          },
        ]
      : []),
    ...(!isCancel
      ? [
          {
            key: 'application',
            url: '/application',
            name: 'Ứng dụng',
            icon: SquaresPlusOutlined,
          },
        ]
      : []),
    {
      key: 'wallet',
      url: '/wallet',
      name: 'Ví Tera',
      icon: WalletOutlined,
    },
    ...(['owner'].includes(type) && !isCancel
      ? [
          {
            key: 'permission-group',
            url: '/permission-group',
            name: 'Nhóm quyền',
            icon: ShieldCheckOutlined,
          },
          {
            key: 'service-package',
            url: '/service-package',
            name: 'Gói dịch vụ',
            icon: AcademicCapOutlined,
          },
        ]
      : []),
    {
      key: 'invoice',
      url: '/invoice',
      name: 'Hóa đơn',
      icon: DocumentCheckOutlined,
    },
    {
      key: 'store',
      url: '/store',
      name: 'Cửa hàng',
      icon: BuildingStorefrontOutlined,
    },
  ];
  return (
    <>
      <div className="flex items-center justify-between">
        <img src={LogoTera} alt="logo-terasolutions" />
        {/* <HomeSolid
          className="w-[30px] h-[30px] cursor-pointer"
          onClick={() => navigate('/dashboard')}
          color="#fff"
        /> */}
      </div>
      <div className="flex flex-col gap-[5px] h-full overflow-auto">
        {MENU.map((menu) => (
          <div
            key={menu.key}
            className="flex flex-col"
            onClick={onClickItemMenu}
          >
            <Link to={menu.url}>
              <div
                className={`flex items-center justify-start gap-5 px-4 py-2.5 rounded-[5px]  ${
                  location.pathname.includes(menu.key) ? `bg-[#fff]` : ''
                }`}
              >
                <menu.icon
                  className="w-[20px] h-[20px]"
                  color={
                    location.pathname.includes(menu.key) ? `#0095D9` : `#fff`
                  }
                />
                <p
                  className={`font-semibold text-base ${
                    location.pathname.includes(menu.key)
                      ? `text-[#0095D9] `
                      : `text-white`
                  }`}
                >
                  {menu.name}
                </p>
              </div>
            </Link>
            {location.pathname.includes(menu.key) && menu.sub_menu && (
              <div className="flex flex-col ml-[54px] relative">
                <div className="absolute w-1 h-[calc(100%-20px)] left-0 top-5 bg-[#9CA3AF]" />
                {menu.sub_menu.map((sm) => (
                  <div key={sm.key} className="mt-5 relative">
                    <Link to={`${sm.url}`}>
                      <p
                        className={`font-medium text-base pl-2.5 border-l-4 z-10 ${
                          location.pathname.startsWith(sm.url)
                            ? `border-[#0095D9] text-[#0095D9] border-l-white`
                            : `border-[#9CA3AF]`
                        }`}
                      >
                        <span className="text-white text-sm font-semibold">
                          {sm.name}
                        </span>
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Menu;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useStores } from '_common/hooks';
import PaymentInformation from 'pages/Payment/containers/PaymentInformation';
import Registration from 'pages/ServicePackage/containers/Registration';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, Button, Col, Input, Row } from 'tera-dls';
import CartApi from './api';
import CartTable from './containers/CartTable';
import useTriggerQuickPage from './Hooks/useTriggerQuickPage';
import { CART_URL } from './url';
import { PAYMENT_URL } from 'pages/Payment/url';
import { toJS } from 'mobx';
import NoPermission from '_common/component/NoPermission';

const Cart = () => {
  const {
    cartStore: { updateCartIds, cart_ids, clear },
    authStore,
  } = useStores();
  const { user } = authStore;
  const { type } = toJS(user);
  const { business } = toJS(user);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [replaceModel, setReplaceModel] = useState<any>({ open: false });
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const isCancel = business?.status === 'cancelled';

  useTriggerQuickPage((nextPathname) => {
    if (nextPathname.includes(PAYMENT_URL) || nextPathname.includes(CART_URL))
      return;
    clear();
  });

  const handleBack = () => navigate(-1);

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-cart-list'],
    () =>
      CartApi.getList({
        params: { page: 1, limit: 1000 },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (cart_ids?.length > 0) {
      setSelectedRowKeys(cart_ids);
    }
  }, [cart_ids]);

  const { data: payment } = useQuery(
    ['get-cart-payment', selectedRowKeys],
    () =>
      CartApi.getPayment({
        params: { item_selected: selectedRowKeys },
      }),
    {
      staleTime: 300000,
      enabled: selectedRowKeys?.length > 0,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    selectedRowKeys?.length > 0 &&
      queryClient.invalidateQueries(['get-cart-payment']);
  }, [selectedRowKeys]);

  const { mutate: mutateUpdateQuantity } = useMutation(
    (variables: any) => CartApi.updateQuantity(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          if (cart_ids.includes(res?.data?.id)) {
            queryClient.invalidateQueries(['get-cart-payment']);
          }
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleUpdateQuantity = (id: number, quantity: number): void => {
    mutateUpdateQuantity({ id, params: { quantity } });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CartApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-cart-total']);
          if (cart_ids.includes(res?.data?.id)) {
            const newIds = cart_ids?.filter((id) => id !== res?.data?.id);
            setSelectedRowKeys(newIds);
            updateCartIds(newIds);
            queryClient.invalidateQueries(['get-cart-payment']);
          }
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number): void => {
    mutateDelete({ id });
  };

  const handleSubmitCart = () => {
    if (selectedRowKeys?.length > 0) {
      updateCartIds(selectedRowKeys);
      navigate(PAYMENT_URL);
    }
  };
  if (type !== 'owner' || isCancel) {
    return <NoPermission />;
  }
  return (
    <>
      <div className="p-2.5 flex flex-col gap-[15px]">
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={handleBack}
        >
          <ArrowLeftOutlined className="w-4" />
          <span className="italic text-xs space-x-1 text-[#76A9FA]">
            Quay lại
          </span>
        </div>
        <div className="text-[22px] leading-[26px] font-medium">Giỏ hàng</div>
        <Row className="xl:grid-cols-3 gap-2.5 xl:gap-[32px]">
          <Col className="xl:col-span-2 ">
            <div className="p-[16px] border border-gray-100 rounded-[10px]">
              <CartTable
                showReplace={Boolean(response?.allow_change_item)}
                tableProps={{
                  loading: isLoading,
                  hiddenColumns: ['old_package'],
                  rowSelection: {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                      updateCartIds(keys);
                    },
                  },
                }}
                isCart={true}
                value={response?.data}
                onChangeQuantity={handleUpdateQuantity}
                onDelete={handleDelete}
                onReplace={(value) => setReplaceModel({ open: true, ...value })}
              />
            </div>
          </Col>
          <Col className="col-span-1 flex flex-col gap-2.5 xl:gap-[32px] text-gray-700">
            <div className="p-[16px] flex flex-col gap-2.5 xl:gap-[32px] shadow-md bg-gray-50 rounded-[6px]">
              <div className="font-semibold text-base">Mã khuyến mãi</div>
              <div className="flex gap-2.5">
                <Input placeholder="Vui lòng nhập" />
                <Button className="shrink-0 h-full bg-blue-400 hover:bg-blue-500 p-2.5 tracking-[1px]">
                  Áp dụng
                </Button>
              </div>
            </div>
            <PaymentInformation
              value={payment}
              bottomRender={
                <Button
                  disabled={selectedRowKeys?.length === 0 || isLoading}
                  className="rounded-full bg-blue-400 hover:bg-blue-500 py-2.5 text-[13px] leading-[15px]"
                  onClick={handleSubmitCart}
                >
                  Mua hàng
                </Button>
              }
            />
          </Col>
        </Row>
      </div>
      {replaceModel?.open && (
        <Registration
          open={replaceModel?.open}
          onClose={() => setReplaceModel({ open: false })}
          value={replaceModel?.serviceId}
          cartId={replaceModel?.id}
          selectedId={replaceModel?.packageId}
          mode={'change'}
        />
      )}
    </>
  );
};

export default Cart;

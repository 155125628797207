import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import LocationApi from 'states/api/location';
import { ChartDoughnutProps, Spin } from 'tera-dls';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { titleClassName, viewMoreClassName } from '../constants';
import { STORE_URL } from 'pages/Store/constants/url';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);
const Store = () => {
  const [hiddenItems, setHiddenItems] = useState([]);
  const chartRef = useRef(null);
  const navigate = useNavigate();

  const { data: listStore, isLoading } = useQuery(
    ['get-list-store'],
    () => {
      const data = {
        view_all: 1,
        limit: 1000,
      };
      return LocationApi.getList({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  console.log('listStore', listStore);

  const handleLegendClick = (index) => {
    const chart = chartRef.current;

    if (chart) {
      const meta = chart.getDatasetMeta(0);
      const item = meta?.data[index];
      item.hidden = !item?.hidden;

      setHiddenItems((prev) =>
        item.hidden ? [...prev, index] : prev.filter((i) => i !== index),
      );

      chart?.update();
    }
  };

  const optionDoughnut: ChartDoughnutProps['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const data = context.formattedValue;
            return data + ' cửa hàng';
          },
        },
      },
    },
    cutout: '70%',
  };

  const dataDoughNut: any = useMemo(() => {
    const arrColor = ['#31C48D', '#F98080'];
    const data = listStore?.data?.data;

    const active = data?.filter((item) => item?.is_active)?.length ?? 0;
    const inactive = data?.filter((item) => !item?.is_active)?.length ?? 0;

    return {
      labels: ['Hoạt động', 'Ngưng hoạt động'],
      datasets: [
        {
          data: [active, inactive],
          backgroundColor: arrColor,
        },
      ],
    };
  }, [listStore]);

  return (
    <div className="p-6 h-full rounded-2xl bg-white shadow">
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Cửa hàng</h2>
        <span
          className={viewMoreClassName}
          onClick={() => navigate(STORE_URL.list.path)}
        >
          Xem thêm
        </span>
      </div>
      <Spin spinning={isLoading}>
        <div className="relative">
          <Doughnut
            ref={chartRef}
            className="m-auto w-[220px] h-[220px]"
            data={dataDoughNut}
            options={optionDoughnut}
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <p className="text-2xl font-bold">{listStore?.data?.total}</p>
            <p className="text-xs font-light uppercase">Cửa hàng</p>
          </div>
        </div>
        <div className="flex justify-center flex-wrap gap-5 mt-2.5">
          {dataDoughNut.labels.map((label, index) => {
            const percent =
              (dataDoughNut.datasets[0].data[index] / listStore?.data?.total) *
              100;
            const percentShow = Number.isInteger(percent)
              ? percent
              : percent.toFixed(2);
            return (
              <div
                key={index}
                className="flex items-center gap-x-2.5 "
                onClick={() => handleLegendClick(index)}
              >
                <span
                  className="px-2.5 py-1 rounded text-white cursor-pointer"
                  style={{
                    backgroundColor:
                      dataDoughNut.datasets[0].backgroundColor[index],
                    textDecoration: hiddenItems.includes(index)
                      ? 'line-through'
                      : 'none',
                  }}
                >
                  {label}
                </span>
                <span className="font-bold">{percentShow}%</span>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

export default Store;

import { useMutation, useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { MemberApi } from 'pages/EmployeeInformation/api';
import {
  statusTypeColor,
  USER_STATUS,
} from 'pages/EmployeeInformation/constants';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import ChangingPassword from '../ChangingPassword';
import EmployeeDetail from '../Detail';
import EmployeeForm from '../Form';
import Permission from '../Permission';
import UpdateStatus from '../UpdateStatus';
import HoverQuickView from '_common/component/HoverQuickView';

export interface IEmployeeFormRefProps {
  add: () => void;
}
interface IProps {
  isAction?: boolean;
  dataSource?: any;
}

const EmployeeTable = (props: IProps, ref) => {
  const { isAction = true, dataSource } = props;
  const navigate = useNavigate();
  const { search } = useLocation();
  const confirm = useConfirm();
  const queryParams = getQueryParams(search) as any;
  const [formModel, setFormModel] = useState<any>({ open: false });
  const [changingPasswordModel, setChangingPasswordModel] = useState<any>({
    open: false,
  });
  const [detailModel, setDetailModel] = useState<any>({
    open: false,
  });
  const [isAddPermission, setIsAddPermission] = useState({
    open: false,
    id: null,
  });
  const [openUpdateStatus, setOpenUpdateStatus] = useState<any>({
    open: false,
  });

  const {
    data: list,
    refetch,
    isLoading,
  } = useQuery(
    ['get-member-list', queryParams],
    () =>
      MemberApi.getList({
        limit: 10,
        page: 1,
        ...queryParams,
      }),
    {
      enabled: !dataSource,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  // const permission = {
  //   detail: PERMISSION_KEY.MEMBER_INFORMATION_VIEW_DETAIL,
  //   changingPassword: PERMISSION_KEY.MEMBER_INFORMATION_CHANGING_PASSWORD,
  //   authorization: PERMISSION_KEY.MEMBER_INFORMATION_AUTHOR,
  //   update: PERMISSION_KEY.MEMBER_INFORMATION_UPDATE,
  //   delete: PERMISSION_KEY.MEMBER_INFORMATION_DELETE,
  //   add_permission: PERMISSION_KEY.MEMBER_INFORMATION_ADD_PERMISSION,
  // };

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (variable: any) => MemberApi.delete(variable),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleDelete = (id: number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa thành viên',
      content: (
        <div className="break-all">
          <p>Bạn có chắc muốn xóa yêu cầu mua hàng</p>
          <p>
            <b>{name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    dropdownItems.push({
      key: 1,
      label: <a>Xem</a>,
      onClick: () => {
        setDetailModel({ open: true, value: record.id });
      },
    });
    dropdownItems.push({
      key: 2,
      label: <a>Thay đổi mật khẩu</a>,
      onClick: () => {
        setChangingPasswordModel({
          open: true,
          value: { username: record.username, id: record.id },
        });
      },
    });
    dropdownItems.push({
      key: 4,
      label: <a>Cập nhật trạng thái</a>,
      onClick: () => setOpenUpdateStatus({ open: true, value: record?.id }),
    });
    dropdownItems.push({
      key: 3,
      label: <a>Phân quyền</a>,
      onClick: () => {
        setIsAddPermission({
          open: true,
          id: record?.id,
        });
      },
    });
    dropdownItems.push({
      key: 5,
      label: <a>Sửa</a>,
      onClick: () => setFormModel({ open: true, value: record?.id }),
    });
    dropdownItems.push({
      key: 6,
      label: <a className="text-red-500">Xóa</a>,
      onClick: () => handleDelete(record.id, record.full_name),
    });

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (full_name, record) => (
        <HoverQuickView
          avatarUrl={record?.avatar_url}
          email={record?.email}
          name={full_name}
          phone={record?.phone}
          className="line-clamp-2"
        >
          <div className="flex gap-3 items-center">
            <DefaultImage
              src={record?.avatar_url}
              alt={record?.avatar_url}
              className="w-[40px] h-[40px] rounded-full border"
            />
            <span
              onClick={() =>
                isAction && setDetailModel({ open: true, value: record.id })
              }
            >
              {full_name}
            </span>
          </div>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 150,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      width: 150,
      render: (val) =>
        val?.code === 'user_default' &&
        !val?.business_id &&
        val?.type === 'user'
          ? ''
          : val?.title,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Chức danh',
      dataIndex: 'job_title_text',
      width: 150,
      render: (job_title) => <p className="line-clamp-2">{job_title?.title}</p>,
    },
    {
      title: 'Phòng ban/ Bộ phận',
      dataIndex: 'department_text',
      width: 150,
      render: (department) => (
        <p className="line-clamp-2">{department?.title}</p>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'is_active',
      width: 150,
      render: (value) =>
        USER_STATUS[value] && (
          <Tag color={statusTypeColor[value]}>{USER_STATUS[value]}</Tag>
        ),
    },
    ...(isAction
      ? [
          {
            key: 'actions',
            title: '',
            width: 60,
            fixed: 'right',
            render: (_, record) => (
              <ActionDropdown
                dropdownItems={generateDropDownItems(record)}
                trigger="click"
                containerClassName="min-w-fit max-w-fit max-h-fit"
                placement="bottom-end"
              />
            ),
          },
        ]
      : []),
  ];

  useImperativeHandle(
    ref,
    () => ({
      add: () => setFormModel({ open: true }),
    }),
    [ref, setFormModel],
  );

  return (
    <>
      <TableTera
        loading={isLoading || loadingDelete}
        rowKey={'id'}
        columns={columns}
        data={(dataSource || list?.data) ?? []}
        pagination={{
          onChange: handleChangePage,
          total: dataSource?.total || list?.total,
          current: dataSource?.current_page || list?.current_page,
          pageSize: dataSource?.per_page || list?.per_page,
          to: dataSource?.to || list?.to,
          from: dataSource?.from || list?.from,
        }}
      />
      {formModel?.open && (
        <EmployeeForm
          open={formModel?.open}
          onClose={() => setFormModel({ open: false })}
          value={formModel?.value}
        />
      )}
      {changingPasswordModel?.open && (
        <ChangingPassword
          open={changingPasswordModel?.open}
          onClose={() => setChangingPasswordModel({ open: false })}
          value={changingPasswordModel?.value}
        />
      )}
      {detailModel?.open && (
        <EmployeeDetail
          open={detailModel?.open}
          onClose={() => setDetailModel({ open: false })}
          value={detailModel?.value}
        />
      )}
      {isAddPermission.open && (
        <Permission
          open={isAddPermission.open}
          onClose={() => setIsAddPermission({ open: false, id: null })}
          onSuccess={() => console.log()}
          id={isAddPermission.id}
        />
      )}
      {openUpdateStatus?.open && (
        <UpdateStatus
          open={openUpdateStatus?.open}
          onClose={() => {
            setOpenUpdateStatus({ open: false });
          }}
          value={openUpdateStatus?.value}
        />
      )}
    </>
  );
};

export default forwardRef<IEmployeeFormRefProps, IProps>(EmployeeTable);

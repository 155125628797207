import { useMutation, useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import UserApi from 'pages/Application/_api';
import React, { useEffect, useState } from 'react';
import {
  Modal,
  PaginationProps,
  PlusCircleOutlined,
  TrashOutlined,
  notification,
} from 'tera-dls';
import SearchApplication from '../Search';
import ModuleList from './ModuleList';

interface MemberListProps {
  module_id: string;
  open: boolean;
  onClose: () => void;
}

function MemberList({ open, module_id, onClose }: MemberListProps) {
  const [isModify, setIsModify] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });
  const [params, setParams] = useState({
    keyword: '',
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data, isLoading, refetch } = useQuery(
    ['get-user-list', params, pagination],
    () => {
      const data = {
        module_id,
        no_module: isModify ? 1 : 0,
        ...pagination,
        ...params,
      };

      return UserApi.getList(data);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, [isModify, module_id, pagination]);

  const { mutate: addToModule } = useMutation(
    (variable: React.Key[]) => {
      const data = {
        module_id,
        members: variable,
      };

      return UserApi.addToModule(data);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          setIsModify(false);
          setSelectedRowKeys([]);
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const { mutate: removeMemberInModule } = useMutation(
    (variable: number) => {
      const data = {
        module_id,
        members: [variable],
      };

      return UserApi.removeMemberInModule(data);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSearch = (values) => {
    setParams({ ...params, ...values });
  };

  const handleCancel = () => {
    if (isModify) {
      setIsModify(false);
      setSelectedRowKeys([]);
    } else onClose();
  };

  const handleOk = () => {
    if (isModify) {
      addToModule(selectedRowKeys);
    } else onClose();
  };

  const columns: any = [
    {
      title: '',
      dataIndex: 'avatar_url',
      render: (avatar_url) => (
        <DefaultImage
          src={avatar_url}
          alt={avatar_url}
          className="w-[40px] h-[40px] rounded-full border"
        />
      ),
      width: 80,
    },
    {
      title: 'Thông tin thành viên',
      dataIndex: 'full_name',
      width: 200,
      render: (full_name, record) => (
        <div className="flex flex-col gap-y-[5px]">
          <p className="line-clamp-2">{full_name}</p>
          <ModuleList modules={record?.modules} />
        </div>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 200,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Chức danh',
      dataIndex: 'job_title_text',
      width: 200,
      render: (job_title) => <p>{job_title?.title}</p>,
    },
    {
      title: 'Phòng ban/ Bộ phận',
      dataIndex: 'department_text',
      width: 200,
      render: (department) => <p>{department?.title}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 200,
    },
    {
      key: 'actions',
      title: (
        <PlusCircleOutlined
          onClick={() => setIsModify(true)}
          className="w-5 h-5 text-green-500 cursor-pointer"
        />
      ),
      width: 60,
      fixed: 'right',
      align: 'center',
      render: (record) => (
        <TrashOutlined
          className="w-5 h-5 text-red-500 cursor-pointer"
          onClick={() => removeMemberInModule(record?.id)}
        />
      ),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setPagination({
      page:
        pagination?.limit && Number(pageSize) !== Number(pagination?.limit)
          ? 1
          : page,
      limit: pageSize,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const onRow = (record) => ({
    onClick: () => {
      const isOld = selectedRowKeys.includes(record?.id);
      if (isOld) {
        const newSelectRowKeys = selectedRowKeys.filter(
          (i) => i !== record?.id,
        );
        setSelectedRowKeys(newSelectRowKeys);
      } else {
        setSelectedRowKeys((prev) => [...prev, record?.id]);
      }
    },
  });

  return (
    <Modal
      centered={true}
      title="Danh sách thành viên"
      open={open}
      className="sm:w-[95%] xl:w-[1200px]"
      closeIcon={false}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Lưu"
      cancelText={isModify ? 'Hủy' : 'Đóng'}
      okButtonProps={{ className: !isModify && 'hidden' }}
    >
      <div className="flex flex-col gap-y-5">
        <SearchApplication
          onSearch={handleSearch}
          placeholder="Tìm kiếm thành viên"
        />
        <div className="rounded shadow overflow-hidden">
          <TableTera
            columns={columns}
            data={data?.data}
            rowSelection={isModify ? rowSelection : undefined}
            onRow={isModify ? onRow : undefined}
            hiddenColumns={isModify ? ['actions'] : []}
            loading={isLoading}
            pagination={{
              onChange: handleChangePage,
              to: data?.to,
              from: data?.from,
              current: data?.current_page,
              pageSize: data?.per_page,
              total: data?.total,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MemberList;

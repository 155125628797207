import classNames from 'classnames';
import PrimaryButton from 'pages/Dashboard/components/PrimaryButton';
import { containerClassName, titleClassName } from 'pages/Dashboard/constants';
import React from 'react';
import { formatCurrency } from 'tera-dls';
import hand_payment from 'styles/images/dashboard/hand-payment.png';
function Invoice() {
  return (
    <div className={classNames('relative !bg-[#FDF6B2]', containerClassName)}>
      <div className="flex flex-col gap-2.5 sm:gap-5">
        <h2 className={titleClassName}>
          Bạn có (0) hoá đơn cần thanh toán sắp đến hạn.
        </h2>
        <p className="text-base font-semibold text-[#202124]">
          Tổng thành tiền:{' '}
          <span className="underline text-red-500 text-2xl font-semibold">
            {formatCurrency(0)}
          </span>
        </p>
        <PrimaryButton title="Thanh toán" />
      </div>

      <img
        className="hidden sm:block absolute top-1/2 -right-[10px] -translate-y-1/2 w-[185px]  rotate-[10deg]"
        src={hand_payment}
        alt="hand_payment"
      />
    </div>
  );
}

export default Invoice;

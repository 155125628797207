import React from 'react';
import {
  containerClassName,
  titleClassName,
  viewMoreClassName,
} from '../constants';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_INFORMATION_URL } from 'pages/EmployeeInformation/url';
import { MemberApi } from 'pages/EmployeeInformation/api';
import { useQuery } from '@tanstack/react-query';

const Member = () => {
  const navigate = useNavigate();

  const { data: list } = useQuery(
    ['get-member-list'],
    () =>
      MemberApi.getList({
        limit: 10,
        page: 1,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  return (
    <div className={containerClassName}>
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Thành viên</h2>
        <span
          className={viewMoreClassName}
          onClick={() => navigate(EMPLOYEE_INFORMATION_URL.list.path)}
        >
          Xem thêm
        </span>
      </div>
      <div className="max-h-[250px] overflow-auto flex flex-col justify-between gap-2.5">
        {list?.data.map((member) => (
          <div className="flex justify-between items-center gap-2.5 ">
            <div className="flex justify-between items-center gap-2 sm:gap-4">
              <img
                src={member?.avatar_url}
                alt={member?.full_name}
                className="w-[45px] h-[45px] sm:w-[55px] sm:h-[55px] rounded-full shadow-inner flex"
              />
              <p className="text-base line-clamp-2">{member?.full_name}</p>
            </div>
            {/* <p className="text-base">Chức danh - phòng ban - bộ phận</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Member;

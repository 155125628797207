import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  FunnelOutlined,
  PlusCircleOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import EmployeeFilter from './containers/Filter';
import NotPermission from './containers/NotPermission';
import Searching from './containers/Searching';
import EmployeeTable, { IEmployeeFormRefProps } from './containers/Table';
import { IFilter } from './interface';
import NoPermission from '_common/component/NoPermission';

const EmployeeInformation = () => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { search } = useLocation();
  const { user } = authStore;
  const { type, business } = toJS(user);

  const queryParams = getQueryParams(search) as any;
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const actionRef = useRef<IEmployeeFormRefProps>(null);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: IFilter): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleAdd = () => {
    actionRef.current && actionRef.current.add();
  };

  const isCancel = business?.status === 'cancelled';

  if (isCancel) {
    return <NoPermission />;
  }
  if (type === 'individual') {
    return <NotPermission />;
  }

  return (
    <>
      <div className="p-5">
        <div className="flex flex-col gap-2.5 mb-[30px]">
          <div className="text-[#0095D9] text-base font-bold">
            Danh sách thành viên
          </div>
          <div className="flex justify-between">
            <div className="flex gap-2.5">
              <Searching onSearch={handleSearch} />
              <Button
                type="alternative"
                className="!p-2 rounded-xsm"
                icon={
                  <FunnelOutlined className="w-5 h-5 text-gray-400 shrink-0" />
                }
                onClick={() => setOpenFilterModal(true)}
              />
            </div>
            <Button
              className="rounded-full bg-blue-400 hover:bg-blue-600"
              onClick={handleAdd}
            >
              <PlusCircleOutlined className="w-5 h-5" />
              Thêm thành viên
            </Button>
          </div>
        </div>
        <EmployeeTable ref={actionRef} />
      </div>
      {openFilterModal && (
        <EmployeeFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={{
            ...(queryParams?.job_title && {
              job_title: queryParams?.job_title,
            }),
            ...(queryParams?.department && {
              department: queryParams?.department,
            }),
          }}
        />
      )}
    </>
  );
};

export default EmployeeInformation;

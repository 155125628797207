import React from 'react';
import Statistic from './containers/Statistic';
import Bank from './containers/Bank';

function Wallet() {
  return (
    <div className="flex flex-col gap-y-4 p-5">
      <div className="grid xl:grid-cols-2 2xl:grid-cols-3 gap-x-[30px]">
        <Statistic />
        <Bank />
      </div>
    </div>
  );
}

export default Wallet;

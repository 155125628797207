import { useQuery } from '@tanstack/react-query';
import LocationApi from 'states/api/location';
import { Description, Modal, Spin, Tag, notification } from 'tera-dls';

import { messageError } from '_common/constants/message';
import { useEffect } from 'react';
import { StoreStatus, StoreStatusColor } from 'pages/Store/constants';

function DetailStore({ id, open, onCancel }) {
  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-detail-location', id],
    () => LocationApi.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const renderDetail = () => {
    const details = [
      {
        title: 'Tên cửa hàng',
        value: dataDetail?.name,
      },
      {
        title: 'Mã cửa hàng',
        value: dataDetail?.location_id,
      },
      {
        title: 'Địa chỉ',
        value: dataDetail?.landmark,
      },
      {
        title: 'Số điện thoại',
        value: dataDetail?.mobile,
      },
      {
        title: 'Email',
        value: dataDetail?.email,
      },
      {
        title: 'Website',
        value: dataDetail?.website,
      },
      {
        title: 'Trạng thái',
        value: (
          <Tag color={StoreStatusColor[dataDetail?.is_active]}>
            {StoreStatus[dataDetail?.is_active]}
          </Tag>
        ),
      },
    ];
    return details;
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  if (isError) {
    onCancel();
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });
  }

  return (
    <Modal
      title="Chi tiết cấu hình cửa hàng"
      open={open}
      width={500}
      okText="Đóng"
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onCancel}
      onOk={onCancel}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        {renderDetail().map((item, index) => (
          <Description label={item.title} key={index}>
            {item.value}
          </Description>
        ))}
      </Spin>
    </Modal>
  );
}

export default DetailStore;

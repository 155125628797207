import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as IconCart } from 'styles/images/Icons/cart.svg';
import { ReactComponent as IconCoin } from 'styles/images/Icons/coin.svg';
import { ReactComponent as IconHelp } from 'styles/images/Icons/help.svg';
import { ReactComponent as IconProfile } from 'styles/images/Icons/profile.svg';
import backgroundImage from 'styles/images/uiNew/bg-form.png';
import logo from 'styles/images/uiNew/logo.png';

import { useQuery } from '@tanstack/react-query';
import ModalConfirm from '_common/component/ModalConfirm2';
import { useStores } from '_common/hooks';
import { toJS } from 'mobx';
import CartApi from 'pages/Cart/api';
import { CART_URL } from 'pages/Cart/url';
import WalletApi from 'pages/Wallet/_api';
import FirstLogin from 'pages/Welcome/FirstLogin';
import { useEffect, useState } from 'react';
import {
  Badge,
  Bars3BottomLeftSolid,
  Drawer,
  formatNumber,
  Popover,
} from 'tera-dls';
import Content from './Content';
import Menu from './Menu';

function BasicLayout() {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { user } = authStore;
  const [firstLogin, setFirstLogin] = useState<boolean>(false);
  const [isMenu, setIsMenu] = useState<boolean>(false);

  const { business, type, is_first } = toJS(user);

  useEffect(() => {
    is_first === 1 && setFirstLogin(Boolean(is_first));
  }, [is_first]);

  const isCancel = business?.status === 'cancelled';
  const { data: total } = useQuery(
    ['get-cart-total'],
    () => CartApi.countCart(),
    {
      enabled: !!business?.id && type === 'owner',
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const balance = useQuery(['get-balance'], () => WalletApi.getAmount(), {
    cacheTime: 300000,
    staleTime: 300000,
  });

  return (
    <>
      {firstLogin && (
        <FirstLogin open={firstLogin} onClose={() => setFirstLogin(false)} />
      )}
      <div
        className="flex w-full h-screen"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="hidden xl:flex w-[303px] h-full p-3  flex-col gap-y-[38px] shadow-md bg-[#0095D9]">
          <Menu type={type} isCancel={isCancel} />
        </div>
        <div className="w-full xl:w-[calc(100vw-302px)] h-full flex flex-col gap-y-2.5">
          <div className="w-full h-[55px] sm:h-[70px] p-2.5 xl:px-10 flex items-center justify-between gap-3 shadow-md">
            <div className="flex items-center gap-x-2.5">
              <Bars3BottomLeftSolid
                className="xl:hidden w-8 h-8 cursor-pointer text-[#00AFEF]"
                onClick={() => setIsMenu(true)}
              />
              <img
                src={logo}
                className="xl:hidden w-12 cursor-pointer"
                onClick={() => navigate('/dashboard')}
              />
            </div>
            <div className="flex items-center gap-x-2.5">
              <div className="flex gap-x-3 items-center rounded-full bg-gray-200 pr-[14px]">
                <IconCoin className="w-6 h-6 sm:w-8 sm:h-8" color="#4B5563" />
                <span className="font-semibold">
                  {formatNumber(balance.data?.availability_amount)}
                </span>
              </div>
              {business?.id && type === 'owner' && !isCancel && (
                <Badge
                  count={total}
                  showZero={false}
                  overflowCount={99}
                  className="w-[15px] bg-red-400 text-white text-[8px] top-[6px] right-[2px]"
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => navigate(CART_URL)}
                  >
                    <IconCart
                      className="w-6 h-6 sm:w-8 sm:h-8"
                      color="#4B5563"
                    />
                  </span>
                </Badge>
              )}

              <IconHelp className="w-6 h-6 sm:w-8 sm:h-8" color="#4B5563" />
              <Popover
                trigger="click"
                content={<Content />}
                placement="bottom-end"
              >
                <IconProfile
                  className="w-6 h-6 sm:w-8 sm:h-8 cursor-pointer"
                  color="#0095D9"
                />
              </Popover>
            </div>
          </div>
          <div className="h-full overflow-auto flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      <ModalConfirm />

      {isMenu && (
        <Drawer
          open={isMenu}
          containerClassName="p-0"
          placement="left"
          onClose={() => setIsMenu(false)}
        >
          <div className="overflow-hidden h-[100vh] p-3 flex flex-col gap-10 shadow-md bg-[#0095D9]">
            <Menu
              type={type}
              isCancel={isCancel}
              onClickItemMenu={() => setIsMenu(false)}
            />
          </div>
        </Drawer>
      )}
    </>
  );
}

export default observer(BasicLayout);

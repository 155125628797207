import EmptyDashboard from 'styles/images/wallet/empty_dashboard.png';
// import { ReactComponent as EmptyDashboard } from 'styles/images/wallet/empty-dashboard.svg';
import { EllipsisHorizontalOutlined } from 'tera-dls';
import Empty from '../../Empty';
import Item from './Item';

function Revenue() {
  return (
    <div className="h-full p-6 rounded-2xl bg-[#FAFAF9] flex flex-col gap-y-4 2xl:col-span-5 rounded-2xl shadow-[0_1px_8px_0_rgba(0,0,0,0.1)]">
      <div className="flex justify-between items-center">
        <h3 className="uppercase">Doanh thu gần đây</h3>
        <div>
          <EllipsisHorizontalOutlined className="w-5 h-5 cursor-pointer" />
        </div>
      </div>
      <div className="overflow-auto">
        {false ? (
          <>
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
          </>
        ) : (
          <Empty sub="Chưa có dữ liệu" image={<img src={EmptyDashboard} />} />
        )}
      </div>
    </div>
  );
}

export default Revenue;

import {
  containerClassName,
  titleClassName,
  viewMoreClassName,
} from 'pages/Dashboard/constants';
import { mockDataNews } from 'pages/Dashboard/mockData';
import { SERVICE_PACKAGE_URL } from 'pages/ServicePackage/constants/url';
import { useNavigate } from 'react-router-dom';
import { WalletOutlined } from 'tera-dls';

function ServicePackage() {
  const data = mockDataNews;
  const navigate = useNavigate();
  return (
    <div className={containerClassName}>
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Giới thiệu gói dịch vụ</h2>
        <span
          className={viewMoreClassName}
          onClick={() => navigate(SERVICE_PACKAGE_URL.list.path)}
        >
          Xem thêm
        </span>
      </div>
      <div className="grid sm:grid-cols-2 xmd:grid-cols-3 2xl:grid-cols-5 gap-4 2xl:gap-x-[30px] mt-2.5 max-h-[350px] overflow-auto p-2">
        {data.map((item) => (
          <div className="flex flex-col gap-2.5 p-2.5 rounded-2xl bg-gradient-to-b from-[#9FD1FF4D] to-[#79AFF600] hover:shadow-[0_8px_28px_0_rgba(100,100,111,0.2)]">
            <div className="flex items-center justify-between">
              <p className="text-gray-700 font-semibold">{item.title}</p>
              <div className="w-10 h-10 rounded-md shadow flex bg-white">
                <WalletOutlined className="w-6 h-6 text-blue-500 m-auto" />
              </div>
            </div>
            <p className="line-clamp-3 text-gray-500">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicePackage;

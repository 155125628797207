import BankCard from 'pages/Wallet/containers/Bank/BankCard';
import { Col, Row } from 'tera-dls';
import Affiliate from './containers/Affiliate';
import Invoice from './containers/Invoice';
import LatestTransactions from './containers/LatestTransactions';
import Member from './containers/Member';
import News from './containers/News';
import RedirectCrm from './containers/RedirectCrm';
import ServicePackage from './containers/ServicePackage';
import Store from './containers/Store';
import TutorialVideo from './containers/TutorialVideo';
import Footer from './containers/Footer';

const Dashboard = () => {
  return (
    <div className="p-2 sm:p-5 flex flex-col gap-4 sm:gap-6">
      <Row className="grid-cols-6 gap-4 sm:gap-[30px]">
        <Col className="col-span-6 xmd:col-span-3 2xl:col-span-4">
          <RedirectCrm />
        </Col>
        <Col className="col-span-6 xmd:col-span-3 2xl:col-span-2">
          <BankCard />
        </Col>
      </Row>
      <Row className="xmd:grid-cols-2 gap-4 sm:gap-[30px]">
        <Col>
          <LatestTransactions />
        </Col>
        <Col className="flex flex-col gap-4 sm:gap-6">
          <Invoice />
          <Affiliate />
        </Col>
      </Row>
      <Row className="xmd:grid-cols-2 gap-4 sm:gap-[30px]">
        <Col>
          <Member />
        </Col>
        <Col>
          <Store />
        </Col>
      </Row>
      <Row>
        <ServicePackage />
      </Row>
      <Row className="xmd:grid-cols-2 gap-4 sm:gap-[30px]">
        <Col>
          <News />
        </Col>
        <Col>
          <TutorialVideo />
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Dashboard;

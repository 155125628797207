import React from 'react';
import ImageCrm from 'styles/images/dashboard/redirect-crm.png';
import PrimaryButton from '../components/PrimaryButton';
import { useStores } from '_common/hooks';
import { updateQueryParams } from 'tera-dls';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';

const RedirectCrm = () => {
  const { authStore } = useStores();

  const handleRedirectLink = (link) => {
    if (!link) return;

    const bodyParams = {
      access_id: authStore?.access_id,
    };

    const queryParams = updateQueryParams({
      client_id: 'tera',
      req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
    });
    window.open(`${link}${queryParams}`, '_self');
  };

  return (
    <div className="flex flex-col justify-between h-full p-2.5 sm:p-6 bg-[#E1EFFE] rounded-2xl h-full shadow gap-2.5">
      <div className="flex justify-between gap-x-2.5">
        <div className="flex flex-col justify-between">
          <h2 className="text-5xl font-semibold tracking-[10%] leading-[72px]">
            CRM
          </h2>
          <PrimaryButton
            title="Truy cập CRM"
            onClick={() =>
              handleRedirectLink('https://app.dev.teravn.com/auth/check-auth')
            }
          />
        </div>
        <img src={ImageCrm} alt="img-crm" className="hidden sm:block" />
      </div>
      <p className="text-base font-semibold tracking-[10%]">
        Sự kết hợp giữa các chiến lược và công nghệ để cải thiện, xây dựng các
        mối quan hệ với khách hàng hiện tại cũng như khách hàng tiềm năng
      </p>
    </div>
  );
};

export default RedirectCrm;

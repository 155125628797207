import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectJobTitle from '_common/dof/Select/SelectJobTitle';
import SelectPermissionGroup from '_common/dof/Select/SelectPermissionGroup';
import SelectPosition from '_common/dof/Select/SelectPosition';
import useConfirm from '_common/hooks/useConfirm';
import { MemberApi } from 'pages/EmployeeInformation/api';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  formKey?: any;
  value?: any;
  onSuccess?: (value: any) => void;
}

const EmployeeForm = (props: IProps) => {
  const { onClose, open, value: id, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [avatar, setAvatar] = useState<any>();
  const isDirty = form?.formState?.isDirty;

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      variables?.id ? MemberApi.update(variables) : MemberApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onClose();
          queryClient.invalidateQueries(['get-member-list']);
          notification.success({
            message: res?.msg,
          });
          !id &&
            onSuccess &&
            onSuccess({ id: res?.data?.id, name: res?.data?.full_name });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error.data });
      },
    },
  );

  const { data: detail, refetch } = useQuery(
    ['get-member-detail', id],
    () => MemberApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
      onError(error: any) {
        const errorMessage = error?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    if (!detail) return;
    setAvatar({ url: detail.avatar_url });
    form.reset({
      username: detail.username,
      full_name: detail.full_name,
      email: detail.email,
      job_title: detail.job_title,
      department: detail.department,
      phone: detail.phone,
      ...(detail?.role?.code === 'user_default' &&
      !detail?.role?.business_id &&
      detail?.role?.type === 'user'
        ? {}
        : {
            role_id: detail?.role?.id,
          }),
    });
  }, [detail]);

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    mutateAction({
      ...(id && { id }),
      params: {
        username: value.username,
        department: value.department,
        email: value.email,
        full_name: value.full_name,
        job_title: value.job_title,
        password: value.password,
        phone: value.phone,
        role_id: value?.role_id,
        ...(avatar?.url && {
          file_upload: {
            url: avatar?.url,
          },
        }),
        type: 'member',
      },
    });
  };

  return (
    <Modal
      open={open}
      okText="Đồng ý"
      cancelText="Huỷ"
      title={id ? 'Sửa thành viên' : 'Thêm thành viên'}
      destroyOnClose
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      centered={true}
      className="sm:w-[500px] xmd:w-[900px] xl:w-[1200px]"
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <Row className="grid xmd:grid-cols-2 gap-x-4">
          <div className="place-items-center xmd:row-span-2 order-1">
            <ImageBackground
              onChange={setAvatar}
              value={avatar}
              isShowBtnDelete
              object_key={'portal_employee_information'}
              folder={'portal'}
            />
          </div>
          <FormTeraItem
            label="Họ và tên"
            rules={[{ required: 'Vui lòng nhập dữ liệu' }]}
            name="full_name"
            className="order-2"
          >
            <Input
              placeholder="Vui lòng nhập"
              maxLength={100}
              className="py-[13px]"
            />
          </FormTeraItem>
          <FormTeraItem
            className="order-3"
            label="Email"
            name="email"
            rules={[
              {
                required: 'Vui lòng nhập dữ liệu',
                pattern: {
                  value: REGEX.EMAIL,
                  message: 'Sai định dạng email',
                },
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" className="py-[13px]" />
          </FormTeraItem>
          <FormTeraItem
            className="order-7 xmd:order-4"
            name="username"
            label="Tên đăng nhập"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.USERNAME),
                  message: messageValidate.username,
                },
                minLength: {
                  value: 1,
                  message: 'Tên đăng nhập tối thiểu 1 ký tự',
                },
                maxLength: {
                  value: 50,
                  message: 'Tên đăng nhập không vượt quá 50 ký tự',
                },
              },
            ]}
          >
            <Input
              placeholder="Vui lòng nhập"
              className="py-[13px]"
              disabled={!!id}
            />
          </FormTeraItem>
          <FormTeraItem
            className="order-4 xmd:order-5"
            label="Số điện thoại"
            name="phone"
            rules={[
              {
                pattern: {
                  value: REGEX.PHONE_NUMBER,
                  message: 'Số điện thoại không hợp lệ',
                },
              },
              {
                minLength: {
                  value: 8,
                  message: 'Nhập tối thiểu 8 kí tự',
                },
              },
              { required: 'Vui lòng nhập dữ liệu' },
            ]}
          >
            <Input maxLength={20} className="py-[13px]" />
          </FormTeraItem>
          <FormTeraItem
            name={'job_title'}
            label="Chức danh"
            className="order-5 xmd:order-7"
          >
            <SelectJobTitle placeholder="Vui lòng chọn" className="py-[4px]" />
          </FormTeraItem>
          {!id && (
            <>
              <FormTeraItem
                className="order-8 xmd:order-6"
                name="password"
                label="Mật khẩu"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: messageValidate.password,
                    },
                    minLength: {
                      value: 8,
                      message: 'Mật khẩu tối thiểu 8 ký tự',
                    },
                    maxLength: {
                      value: 16,
                      message: 'Mật khẩu không vượt quá 16 ký tự',
                    },
                  },
                ]}
              >
                <InputPassword
                  placeholder="Vui lòng nhập"
                  className="py-[13px]"
                />
              </FormTeraItem>
              <FormTeraItem
                className="order-9 xmd:order-8"
                name="re_password"
                label="Xác nhận mật khẩu"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: messageValidate.password,
                    },
                    minLength: {
                      value: 8,
                      message: 'Mật khẩu tối thiểu 8 ký tự',
                    },
                    maxLength: {
                      value: 16,
                      message: 'Mật khẩu không vượt quá 16 ký tự',
                    },
                    validate: {
                      existed: (value, values) => {
                        const password = values?.password.trim();
                        if (value.trim() !== password) {
                          return 'Nhập lại mật khẩu không trùng với mật khẩu mới';
                        }
                      },
                    },
                  },
                ]}
              >
                <InputPassword
                  placeholder="Vui lòng nhập"
                  className="py-[13px]"
                />
              </FormTeraItem>
            </>
          )}
          <FormTeraItem
            name={'department'}
            label="Phòng ban/ Bộ phận"
            className="order-6 xmd:order-9"
          >
            <SelectPosition placeholder="Vui lòng chọn" className="py-[4px]" />
          </FormTeraItem>
          <FormTeraItem
            className="order-10 xmd:order-10"
            name="role_id"
            label="Phân quyền"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectPermissionGroup
              paramsApi={{ include_id: detail?.role?.id }}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default EmployeeForm;

import classNames from 'classnames';
import PrimaryButton from 'pages/Dashboard/components/PrimaryButton';
import BgAffiliate from 'styles/images/affiliates/section-affiliate.png';
import { containerClassName } from 'pages/Dashboard/constants';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import RegisterModal from './Affiliate/RegisterModal';
import SuccessModal from './Affiliate/SuccessModal';

function Affiliate() {
  const queryClient = useQueryClient();
  const [openRegister, setOpenRegister] = useState<boolean>(false);
  const [openRegisterSuccess, setOpenRegisterSuccess] =
    useState<boolean>(false);

  return (
    <>
      <div
        className={classNames(
          'flex flex-col gap-y-2.5 !bg-[#E5EDFF]',
          containerClassName,
        )}
      >
        <h2 className="font-bold text-blue-800 text-3xl sm:text-4xl leading-[40px] sm:leading-[60px]">
          AFFILIATES
        </h2>
        <div className="flex items-center justify-between gap-x-2.5">
          <ul className="py-1 text-base text-[#6B7280] list-disc ml-5 leading-[30px]">
            <li>Kiếm 25% hoa hồng cho mỗi lần bán hàng</li>
            <li>Giúp thiết kế và xây dựng trải nghiệm tốt hơn</li>
            <li>
              Tạo nguồn doanh thu mới bằng cách truyền bá đến bạn bè, đồng
              nghiệp và khách hàng
            </li>
          </ul>
          <img
            src={BgAffiliate}
            alt="section-affiliate"
            className="hidden sm:block shrink-0 xmd:hidden 2xl:block"
          />
        </div>
        <PrimaryButton
          title="Tham gia ngay"
          onClick={() => setOpenRegister(true)}
        />
      </div>

      {openRegister && (
        <RegisterModal
          open={openRegister}
          onClose={() => setOpenRegister(false)}
          onSuccess={() => {
            setOpenRegisterSuccess(true);
          }}
        />
      )}
      {openRegisterSuccess && (
        <SuccessModal
          open={openRegisterSuccess}
          onClose={() => {
            setOpenRegisterSuccess(false);
            queryClient.invalidateQueries(['get_profile']);
          }}
        />
      )}
    </>
  );
}

export default Affiliate;

import SuccessAffiliate from 'styles/images/dashboard/bg_success_affiliate.png';
import { Button, Modal } from 'tera-dls';
import Hands from 'styles/images/dashboard/hands.png';

interface IProps {
  open: boolean;
  onClose?: () => void;
}
const SuccessModal = (props: IProps) => {
  const { onClose, open } = props;

  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      className="w-[95vw] sm:w-[630px]"
      maskClosable={false}
      open={open}
      centered={true}
      modalRender={() => (
        <div className="relative">
          <img src={SuccessAffiliate} alt="" className="w-full" />
          <div className="absolute top-[50%] sm:top-[30%] left-[50%] -translate-x-[50%] -translate-y-[40%] sm:translate-y-0  flex flex-col gap-2.5 items-center">
            <img src={Hands} className="hidden sm:block w-[209px] h-auto" />
            <div className="text-gray-800 text-center text-xl sm:text-[30px] sm:leading-[42px] font-semibold">
              <p>Bạn đã đăng ký</p>
              <p>AFFILIATES thành công!</p>
            </div>
            <Button
              onClick={onClose}
              className="rounded-full py-[8px] w-[100px] text-base"
            >
              Đóng
            </Button>
          </div>
        </div>
        // <div
        //   className="bg-none gap-[50px] justify-end text-white rounded-[32px] p-[30px] flex flex-col items-center h-[700px]"
        //   style={{
        //     backgroundImage: `url(${SuccessAffiliate})`,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'center',
        //     backgroundPosition: 'center top',
        //   }}
        // >
        //   <div className="flex flex-col gap-2.5 items-center">
        //     <img src={Hands} className="w-[209px] h-auto" />
        //     <div className=" text-gray-800 text-center text-[30px] leading-[42px] font-semibold">
        //       <p>Bạn đã đăng ký</p>
        //       <p>AFFILIATES thành công!</p>
        //     </div>
        //   </div>
        //   <div className="mb-[50px]">
        //     <Button
        //       onClick={onClose}
        //       className="rounded-full py-[8px] w-[100px] text-base"
        //     >
        //       Đóng
        //     </Button>
        //   </div>
        // </div>
      )}
    />
  );
};

export default SuccessModal;

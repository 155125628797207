import { useMutation } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import AffiliateApi from 'pages/Dashboard/_api/affiliate';
import registrationAffiliate from 'styles/images/dashboard/registration_affiliate.png';
import { Modal, XMarkOutlined } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => any;
}
const RegisterModal = (props: IProps) => {
  const { onClose, open, onSuccess } = props;

  const { mutate: mutateAction } = useMutation(() => AffiliateApi.register(), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        onClose();
        onSuccess && onSuccess();
      }
    },
    onError(error: any) {
      ErrorToast({ errorProp: error?.data });
    },
  });

  const handleRegister = () => {
    mutateAction();
  };
  handleRegister;
  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      onCancel={onClose}
      className="sm:w-[600px] xmd:w-[900px] xl:w-[1200px]"
      maskClosable={false}
      open={open}
      centered={true}
      modalRender={() => (
        <div className="relative">
          <img src={registrationAffiliate} alt="" className="w-full" />
          <div className=" w-full">
            <XMarkOutlined
              className="w-6 sm:w-10 xmd:w-[50px] absolute top-[10px] right-[10px] xmd:top-[20px] xmd:right-[20px] cursor-pointer text-white"
              onClick={onClose}
            />
          </div>
          <div className="absolute bottom-[10%] left-[5%] flex flex-col gap-2 xmd:gap-5 w-full ">
            <h3 className="hidden sm:block text-base text-white">
              Đăng ký AFFILIATES miễn phí.
            </h3>
            <div
              onClick={handleRegister}
              className="cursor-pointer py-1 sm:py-2 xmd:py-[14px] font-semibold rounded-[51px] w-[100px] sm:w-[132px] flex justify-center text-base"
              style={{
                background:
                  'linear-gradient(133.99deg, #18A1CC 16.37%, #92D1FF 94.77%)',
                boxShadow: '0px 2px 10px rgba(101, 192, 237, 0.5)',
              }}
            >
              Đăng ký
            </div>
          </div>
          {/* <div
            className="bg-white text-white rounded-[50px] p-[30px] flex flex-col items-center justify-between"
            style={{
              backgroundImage: `url(${registrationAffiliate})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            
            
          </div> */}
        </div>
      )}
    />
  );
};

export default RegisterModal;

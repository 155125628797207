import React, { useEffect, useState } from 'react';
import Searching from './containers/Searching';
import {
  Button,
  FunnelOutlined,
  getQueryParams,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import { useLocation, useNavigate } from 'react-router-dom';
import InvoiceTable from './containers/Table';
import { useQuery } from '@tanstack/react-query';
import InvoiceApi from './api';
import InvoiceFilter from './containers/Filter';

const Invoice = () => {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const navigate = useNavigate();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-invoice-list', queryParams],
    () => {
      return InvoiceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...queryParams,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  return (
    <>
      <div className="flex flex-col gap-y-4 p-5">
        <div className="portal-heading">Hóa Đơn</div>
        <div className="flex gap-x-2.5">
          <Searching onSearch={handleSearch} />
          <Button
            onClick={() => setOpenFilterModal(true)}
            type="alternative"
            className="rounded-xsm py-1 px-[6px]"
            icon={
              <FunnelOutlined className="text-gray-500 rounded-[4px] shrink-0" />
            }
          />
        </div>
        <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden h-full">
          <InvoiceTable
            loading={isLoading}
            data={response?.data}
            pagination={{
              onChange: handleChangePage,
              to: response?.to,
              from: response?.from,
              current: response?.current_page,
              pageSize: response?.per_page,
              total: response?.total,
            }}
          />
        </div>
      </div>
      {openFilterModal && (
        <InvoiceFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default Invoice;

import React from 'react';
import { ReactComponent as IconYoutube } from 'styles/images/dashboard/youtube.svg';
import { ReactComponent as IconFacebook } from 'styles/images/dashboard/facebook.svg';
import { ReactComponent as IconLinkedin } from 'styles/images/dashboard/instagram.svg';
import { ReactComponent as IconTiktok } from 'styles/images/dashboard/tiktok.svg';

const data = [
  {
    icon: <IconFacebook />,
    url: 'https://www.facebook.com/terasolutionsvn',
  },
  {
    icon: <IconLinkedin />,
    url: 'https://www.linkedin.com/in/tera-solutions-4632432a2/',
  },
  {
    icon: <IconTiktok />,
    url: 'https://www.tiktok.com/@terasolutions1?lang=en',
  },
  {
    icon: <IconYoutube />,
    url: 'https://www.youtube.com/@TeraSolutionsVN',
  },
];

function Footer() {
  return (
    <div className="p-4 xmd:p-[30px] text-center">
      <div className="flex justify-center gap-x-8 ">
        {data.map((item) => (
          <a
            className="w-[50px] h-[50px] rounded bg-white flex justify-center duration-400 items-center hover:-translate-y-1 cursor-pointer hover:-translate-y-[4px] transition-all duration-400 hover:shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]"
            href={item.url}
            target="_blank"
          >
            {item.icon}
          </a>
        ))}
      </div>
      <p className="text-[#393939] text-base mt-2.5">Theo dõi chúng tôi</p>
    </div>
  );
}

export default Footer;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoPermission from '_common/component/NoPermission';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { toJS } from 'mobx';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  formatDate,
  getQueryParams,
  notification,
  PaginationProps,
  PlusCircleOutlined,
  updateQueryParams,
} from 'tera-dls';
import PermissionGroupApi from './_api';
import { PERMISSION_GROUP_URL } from './constants/url';
import Config from './containers/Config';
import DetailPermission from './containers/Detail';
import FormPermissionGroup from './containers/Form';
import MemberList from './containers/MemberList';
import SearchPermissionGroup from './containers/Search';

function PermissionGroup() {
  const queryClient = useQueryClient();
  const [isOpenForm, setIsOpenForm] = useState({
    open: false,
    id: null,
  });
  const [isOpenConfig, setIsOpenConfig] = useState({
    open: false,
    id: null,
  });
  const [isOpenDetail, setIsOpenDetail] = useState({
    open: false,
    id: null,
  });
  const [isOpenMember, setIsOpenMember] = useState({
    open: false,
    id: null,
  });

  const navigate = useNavigate();
  const confirm = useConfirm();

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { authStore } = useStores();
  const { user } = authStore;
  const { business } = toJS(user);

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${PERMISSION_GROUP_URL.list.path}${paramString}`);
  };

  const handleSearch = (value) => {
    handleUpdateFilter({ keyword: value?.keyword, page: 1 });
  };

  const permissionGroupList = useQuery(
    ['get-permission-group-list', queryParams],
    () => {
      const params = {
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        ...queryParams,
      };
      return PermissionGroupApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const deletePermission = useMutation(
    (id: number) => PermissionGroupApi.delete({ id }),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-permission-group-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateFilter({
      page: Number(pageSize) !== Number(queryParams?.limit) ? 1 : page,
      limit: pageSize,
    });
  };

  const handleDelete = (item) => {
    confirm.warning({
      title: 'Xác nhận xóa nhóm quyền',
      content: (
        <>
          <p>Bạn có chắc muốn xóa nhóm quyền</p>
          <p>
            <b className="break-word">{item?.name}</b> này không?
          </p>
        </>
      ),
      onOk: async () => deletePermission.mutate(item?.id),
    });
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    const notDelete =
      item?.is_default === 1 && item?.type === 'user' && !!item?.business_id;
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setIsOpenDetail({
          open: true,
          id: item?.id,
        });
      },
    });
    dropdownItems.push({
      key: 2,
      label: 'Cấu hình quyền',
      onClick: () => {
        setIsOpenConfig({
          open: true,
          id: item?.id,
        });
      },
    });
    dropdownItems.push({
      key: 3,
      label: 'DS thành viên',
      onClick: () => {
        setIsOpenMember({ open: true, id: item?.id });
      },
    });
    dropdownItems.push({
      key: 4,
      label: 'Sửa',
      onClick: () => {
        setIsOpenForm({ open: true, id: item?.id });
      },
    });
    !notDelete &&
      dropdownItems.push({
        key: 5,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(item),
      });
    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã quyền',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Tên quyền',
      dataIndex: 'title',
      width: 200,
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) =>
        created_at && formatDate(created_at, 'DD/MM/YYYY - HH:mm'),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updated_at',
      width: 150,
      render: (updated_at) =>
        updated_at && formatDate(updated_at, 'DD/MM/YYYY - HH:mm'),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 150,
      render: (note) => <p className="line-clamp-2">{note}</p>,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  const isCancel = business?.status === 'cancelled';
  if (isCancel) {
    return <NoPermission />;
  }
  return (
    <div className="flex flex-col gap-y-4 p-5">
      <h1 className="portal-heading">Danh sách nhóm quyền</h1>
      <div className="flex justify-between gap-x-2.5">
        <SearchPermissionGroup onSearch={handleSearch} />

        <Button
          onClick={() => setIsOpenForm({ open: true, id: null })}
          className="rounded-full shrink-0 bg-blue-400"
        >
          <div className="flex items-center gap-1 shrink-0">
            <PlusCircleOutlined className="w-5 h-5" />
            <span>Thêm nhóm quyền</span>
          </div>
        </Button>
      </div>
      <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden h-full">
        <TableTera
          rowKey={'id'}
          columns={columns}
          loading={permissionGroupList.isLoading}
          data={permissionGroupList.data?.data || []}
          pagination={{
            onChange: handleChangePage,
            to: permissionGroupList.data?.to,
            from: permissionGroupList.data?.from,
            current: permissionGroupList.data?.current_page,
            pageSize: permissionGroupList.data?.per_page,
            total: permissionGroupList.data?.total,
          }}
        />
      </div>

      {isOpenForm.open && (
        <FormPermissionGroup
          open={isOpenForm.open}
          id={isOpenForm.id}
          onCancel={() => {
            setIsOpenForm({
              open: false,
              id: null,
            });
          }}
          onRefetch={() => {
            queryClient.invalidateQueries(['get-permission-group-list']);
          }}
        />
      )}
      {isOpenConfig.open && (
        <Config
          open={isOpenConfig.open}
          id={isOpenConfig.id}
          onCancel={() => {
            setIsOpenConfig({
              open: false,
              id: null,
            });
          }}
          onRefetch={() => {
            queryClient.invalidateQueries(['get-permission-group-list']);
          }}
        />
      )}
      {isOpenDetail.open && (
        <DetailPermission
          open={isOpenDetail.open}
          id={isOpenDetail.id}
          onCancel={() => {
            setIsOpenDetail({
              open: false,
              id: null,
            });
          }}
        />
      )}
      {isOpenMember.open && (
        <MemberList
          open={isOpenMember.open}
          id={isOpenMember.id}
          onCancel={() => {
            setIsOpenMember({
              open: false,
              id: null,
            });
          }}
        />
      )}
    </div>
  );
}

export default PermissionGroup;

import { useQuery } from '@tanstack/react-query';
import WalletApi from 'pages/Wallet/_api';
import { useState } from 'react';
import BgBalance from 'styles/images/wallet/bg-balance.png';
import { formatCurrency } from 'tera-dls';
import WithDrawal from '../WithDrawal';
import ButtonWallet from './ButtonWallet';

function Balance() {
  const [recharge, setRecharge] = useState<any>({ open: false });

  const balance = useQuery(['get-balance'], () => WalletApi.getAmount(), {
    cacheTime: 300000,
    staleTime: 300000,
  });

  return (
    <div className="relative z-50">
      <div className="absolute shadow-[0_4px_19px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] w-[calc(100%-50px)] h-1/2 rounded-[6px] -bottom-[20px] left-[50%] -translate-x-1/2 bg-gray-100 -z-30"></div>
      <div className="absolute shadow-[0_4px_19px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] w-[calc(100%-110px)] h-1/2 rounded-[6px] -bottom-[30px] left-[50%] -translate-x-1/2 bg-gray-200 -z-40"></div>
      <div
        className="w-full p-6 rounded-2xl grid 2xl:grid-cols-2 shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]"
        style={{
          backgroundImage: `url(${BgBalance})`,
          backgroundSize: '100% 100%',
        }}
      >
        <div className="flex flex-col justify-between gap-2.5 2xl:gap-0">
          <h2 className="uppercase text-gray-200">Số dư gốc</h2>
          <div>
            <span className="text-[45px] text-white leading-[58px] tracking-[6px] rum-raisin">
              {formatCurrency(balance.data?.original_amount)}
            </span>
            <p className="text-base ">
              <span className="text-white">Khả dụng: </span>
              <span className="text-yellow-200">
                {formatCurrency(balance.data?.availability_amount)}
              </span>
            </p>
          </div>
          <div className="flex gap-x-6 items-center">
            <ButtonWallet
              title="Nạp tiền"
              typeBtn="recharge"
              onClick={() => setRecharge({ open: true, type: 'recharge' })}
            />
            <ButtonWallet
              title="Rút tiền"
              typeBtn="withdrawal"
              onClick={() => setRecharge({ open: true, type: 'withdrawal' })}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-[25px] border-t 2xl:border-t-0 2xl:border-l border-dashed 2xl:pl-10 mt-2.5 2xl:mt-0 pt-2.5 2xl:pt-0">
          <div className="flex flex-col gap-y-2.5">
            <span className="text-gray-200 uppercase">Số dư tiếp thị</span>
            <span className="text-[45px] text-white leading-[58px] tracking-[6px] rum-raisin">
              {formatCurrency(balance.data?.marketing_amount)}
            </span>
          </div>
          <div className="flex flex-col gap-y-2.5">
            <span className="text-gray-200 uppercase">Số dư khuyến mãi</span>
            <span className="text-[45px] text-white leading-[58px] tracking-[6px] rum-raisin">
              {formatCurrency(balance.data?.promotion_amount)}
            </span>
          </div>
        </div>
      </div>

      {recharge?.open && (
        <WithDrawal
          open={recharge}
          type={recharge?.type}
          onClose={() => setRecharge(false)}
        />
      )}
    </div>
  );
}

export default Balance;

export const titleClassName =
  'line-clamp-1 tracking-widest text-[#343C6A] text-xl xmd:text-2xl font-semibold mb-2.5';
export const viewMoreClassName =
  'text-blue-600 text-sm font-light cursor-pointer hover:underline shrink-0';
export const containerClassName =
  'p-2.5 sm:p-6 h-full rounded-2xl bg-white shadow';

export const TutorialVideos = [
  {
    id: 1,
    url: 'https://youtu.be/UPyLIl6EeIs',
    thumbnail: 'https://img.youtube.com/vi/UPyLIl6EeIs/0.jpg',
    title: 'HDSD: Quản lý quy trình Mua hàng - Module Mua hàng',
  },
  {
    id: 2,
    url: 'https://youtu.be/o514t_9dg0s',
    thumbnail: 'https://img.youtube.com/vi/o514t_9dg0s/0.jpg',
    title: 'HDSD: Quản lý quy trình bán hàng - Module Bán hàng',
  },
  {
    id: 3,
    url: 'https://youtu.be/VbcbP1YSgPk',
    thumbnail: 'https://img.youtube.com/vi/VbcbP1YSgPk/0.jpg',
    title: 'HDSD: Quản lý khách hàng - Module Bán hàng',
  },
  {
    id: 4,
    url: 'https://youtu.be/t25JUbpk8eA',
    thumbnail: 'https://img.youtube.com/vi/t25JUbpk8eA/0.jpg',
    title: 'HDSD: Quản lý nhà cung cấp - Module Mua hàng',
  },
  {
    id: 5,
    url: 'https://youtu.be/dYjXlTOlawg',
    thumbnail: 'https://img.youtube.com/vi/dYjXlTOlawg/0.jpg',
    title: 'HDSD: Quản lý đơn trả hàng mua - Module Mua hàng',
  },
  {
    id: 6,
    url: 'https://youtu.be/mttcLoe1KnQ',
    thumbnail: 'https://img.youtube.com/vi/mttcLoe1KnQ/0.jpg',
    title: 'HDSD: Quản lý đơn trả hàng bán - Module Bán hàng',
  },
];

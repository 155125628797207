import React from 'react';
import { ArchiveBoxOutlined, formatCurrency } from 'tera-dls';
import { containerClassName, titleClassName } from '../constants';

const LatestTransactions = () => {
  return (
    <div className={containerClassName}>
      <h2 className={titleClassName}>Giao dịch gần đây</h2>
      <div className="flex flex-col justify-between gap-2.5">
        <div className="flex justify-between items-center gap-2.5">
          <div className="flex justify-between items-center gap-2 sm:gap-4">
            <div className="w-[45px] h-[45px] sm:w-[55px] sm:h-[55px] rounded-full shadow-inner flex shrink-0">
              <ArchiveBoxOutlined className="w-4 h-4 sm:w-6 sm:h-6 m-auto" />
            </div>
            <div className="py-[5px] flex flex-col justify-between">
              <p className="text-blue-500 line-clamp-1">Mua gói dịch vụ</p>
              <p className="text-sm	sm:text-base line-clamp-1">
                Tên gói dịch vụ
              </p>
            </div>
          </div>
          <p className="text-sm	 sm:text-base text-green-500 shrink-0">
            {formatCurrency(10000000)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LatestTransactions;

import Balance from './Balance';
import General from './General';
import History from './History';
import Revenue from './Revenue';

function Statistic() {
  return (
    <div className="2xl:col-span-2 flex flex-col gap-y-8">
      <Balance />
      <div className="pt-[23px] grid 2xl:grid-cols-9 gap-[30px] items-start">
        <General />
        <Revenue />
      </div>
      <History />
    </div>
  );
}

export default Statistic;

import { useMutation, useQuery } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import PermissionGroupApi from 'pages/PermissionGroup/_api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';

interface FormPermissionGroupProps {
  id: string | number;
  open: boolean;
  onCancel: () => void;
  onRefetch: () => void;
}

function FormPermissionGroup({
  id,
  open,
  onCancel,
  onRefetch,
}: FormPermissionGroupProps) {
  const form = useForm();
  const confirm = useConfirm();

  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-permission-group-detail', id],
    () => PermissionGroupApi.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleClose = () => {
    onCancel();
    form.reset();
  };

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (params) => {
      if (id) return PermissionGroupApi.update({ id, params });
      return PermissionGroupApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onRefetch();
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSubmitForm = (values) => {
    if ((isLoading && !!id) || loadingSubmit) return;
    submitForm(values);
  };

  const handleCancel = () => {
    if ((isLoading && !!id) || loadingSubmit) return;
    if (form?.formState?.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetail && !!id) {
      const data = _.pick(dataDetail, ['title', 'code', 'note']);
      form?.reset(data);
    }
  }, [dataDetail, id]);

  if (isError) {
    onCancel();
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });
  }

  return (
    <Modal
      title={!id ? 'Thêm nhóm quyền' : 'Sửa nhóm quyền'}
      open={open}
      okText="Lưu"
      cancelText="Hủy"
      onCancel={handleCancel}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      destroyOnClose
      confirmLoading={loadingSubmit || (isLoading && !!id)}
      className="sm:w-[500px] xmd:w-[700px]"
    >
      <Spin spinning={isLoading && !!id}>
        <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
          <FormTeraItem
            name="code"
            label="Mã quyền"
            rules={[
              {
                required: 'Vui lòng nhập trường này',
              },
              {
                pattern: {
                  value: new RegExp(REGEX.CODE),
                  message: messageValidate.code,
                },
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" maxLength={100} />
          </FormTeraItem>
          <FormTeraItem
            name="title"
            label="Tên quyền"
            rules={[
              {
                required: 'Vui lòng nhập trường này',
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" maxLength={100} />
          </FormTeraItem>
          <FormTeraItem name="note" label="Ghi chú">
            <TextArea placeholder="Vui lòng nhập" rows={5} maxLength={1000} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default FormPermissionGroup;

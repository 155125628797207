import classNames from 'classnames';
import {
  containerClassName,
  titleClassName,
  TutorialVideos,
  viewMoreClassName,
} from 'pages/Dashboard/constants';

function TutorialVideo() {
  return (
    <div className={classNames('bg-gray-100', containerClassName)}>
      <div className="flex justify-between items-center">
        <h2 className={titleClassName}>Video hướng dẫn sử dụng</h2>
        <a
          className={viewMoreClassName}
          href="https://www.youtube.com/@TeraSolutionsVN"
          target="_blank"
        >
          Xem thêm
        </a>
      </div>
      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-x-5 gap-y-4 mt-4 max-h-[460px] overflow-auto">
        {TutorialVideos.map((item) => (
          <a
            href={item.url}
            target="_blank"
            className="flex flex-col gap-[14px] group cursor-pointer"
            key={item.id}
          >
            <img src={item.thumbnail} alt={item.title} className="rounded-md" />
            <p className="text-center font-medium text-sm group-hover:!text-blue-600">
              {item.title}
            </p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default TutorialVideo;

import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import PaymentMethodApi from 'pages/Wallet/_api/admin';
import {
  TransactionMethod,
  TransactionStatus,
  TransactionStatusColor,
} from 'pages/Wallet/constants';
import { useState } from 'react';
import { ReactComponent as IconRecharge } from 'styles/images/wallet/recharge.svg';
import { ReactComponent as IconWithdrawal } from 'styles/images/wallet/withdrawal.svg';
import {
  Button,
  CreditCardOutlined,
  formatCurrency,
  formatDate,
  FunnelOutlined,
  PaginationProps,
  Tag,
} from 'tera-dls';
import Header from './Header';
import HistoryFilter from './HistoryFilter';

function History() {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState(null);

  const transactionList = useQuery(
    ['get-transaction-list', queryParams],
    () => {
      const params = {
        get_created_by: 1,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        ...queryParams,
      };
      return PaymentMethodApi.transactionList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setQueryParams({ ...queryParams, page: page, limit: pageSize });
  };

  const handleFilter = (values) => {
    setQueryParams({ ...queryParams, ...values, page: 1 });
  };

  const handleSearch = (value) => {
    setQueryParams({ ...queryParams, keyword: value?.keyword, page: 1 });
  };

  const RenderTransactionType = ({ type }) => {
    const objectType = {
      1: { name: 'Nạp tiền', icon: <IconRecharge /> },
      2: { name: 'Rút tiền', icon: <IconWithdrawal /> },
      3: {
        name: 'Thanh toán',
        icon: <CreditCardOutlined className="text-blue-500 w-4" />,
      },
    };

    return (
      <div className="flex items-center gap-x-2">
        {objectType[type]?.icon}
        <span> {objectType[type]?.name}</span>
      </div>
    );
  };

  const columns: any = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      width: 150,
    },

    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => (
        <RenderTransactionType type={transaction_type} />
      ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      width: 200,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      width: 150,
      render: (method) => TransactionMethod[method],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={TransactionStatusColor[status]}>
          {TransactionStatus[status]}
        </Tag>
      ),
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) =>
        created_at && formatDate(created_at, 'DD/MM/YYYY - HH:mm'),
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'updated_at',
      width: 200,
      render: (updated_at) =>
        updated_at && formatDate(updated_at, 'DD/MM/YYYY - HH:mm'),
    },
  ];

  return (
    <div className="flex p-6 rounded-2xl bg-[#FAFAF9]  flex-col gap-y-4 col-span-5 rounded-2xl shadow-[0_2px_2px_0_rgba(0,0,0,0.1),0_-2px_2px_0_rgba(0,0,0,0.03)]">
      <h3 className="uppercase text-sm text-gray-700">Lịch sử giao dịch</h3>
      <div className="bg-white shadow-[0_2px_2px_0_rgba(0,0,0,0.1)] rounded overflow-hidden">
        <div className="flex items-center justify-end p-2.5 gap-x-2">
          <Header onSearch={handleSearch} initialValue={queryParams} />
          <Button
            type="alternative"
            className="rounded-xsm py-1 px-1 bg-blue-50"
            icon={
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
            }
            onClick={() => setOpenFilter(true)}
          />
        </div>
        <div className="">
          <TableTera
            columns={columns}
            data={transactionList.data?.data}
            pagination={{
              onChange: handleChangePage,
              to: transactionList.data?.to,
              from: transactionList.data?.from,
              current: transactionList.data?.current_page,
              pageSize: transactionList.data?.per_page,
              total: transactionList.data?.total,
            }}
          />
        </div>
      </div>

      {openFilter && (
        <HistoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValue={queryParams}
          onFilter={handleFilter}
        />
      )}
    </div>
  );
}

export default History;

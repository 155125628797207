import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import React, { useEffect } from 'react';
import { Modal, Spin, notification } from 'tera-dls';
// import { StoreStatus } from '..';
import LocationApi from 'states/api/location';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { REGEX } from '_common/constants/common';
import { StoreStatus } from 'pages/Store/constants';

interface FormStoreProps {
  id: string | number;
  open: boolean;
  onCancel: () => void;
  onRefetch: () => void;
}

function FormStore({ id, open, onCancel, onRefetch }: FormStoreProps) {
  const [formRef] = useFormTera();
  const confirm = useConfirm();

  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-detail-location', id],
    () => LocationApi.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleClose = () => {
    onCancel();
    formRef?.current?.reset();
  };

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (params) => {
      if (id) return LocationApi.update({ id, params });
      return LocationApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onRefetch();
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleSubmitForm = (values) => {
    console.log('values', values);

    if ((isLoading && !!id) || loadingSubmit) return;
    submitForm(values);
  };

  const handleCancel = () => {
    if ((isLoading && !!id) || loadingSubmit) return;
    if (formRef?.current?.formState?.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetail && !!id) {
      const data = _.pick(dataDetail, [
        'name',
        'location_id',
        'landmark',
        'mobile',
        'email',
        'website',
        'is_active',
      ]);
      const formData = {
        ...data,
        is_active: data.is_active.toString(),
      };

      formRef?.current?.reset(formData);
    } else {
      formRef?.current?.reset({ is_active: '1' });
    }
  }, [dataDetail, id]);

  const optionsStoreStatus = Object.entries(StoreStatus).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  if (isError) {
    onCancel();
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });
  }

  return (
    <Modal
      title={!id ? 'Thêm cấu hình cửa hàng' : 'Sửa cấu hình cửa hàng'}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      onCancel={handleCancel}
      onOk={() => formRef?.current?.submit()}
      destroyOnClose
      confirmLoading={loadingSubmit || (isLoading && !!id)}
      className="sm:w-[500px]"
    >
      <Spin spinning={isLoading && !!id}>
        <FormTera ref={formRef} onSubmit={handleSubmitForm}>
          <FormTeraItem
            name="name"
            label="Tên cửa hàng"
            rules={[
              {
                required: 'Vui lòng nhập trường này',
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem
            name="location_id"
            label="Mã cửa hàng"
            rules={[
              {
                required: 'Vui lòng nhập trường này',
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem name="landmark" label="Địa chỉ">
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem name="mobile" label="Số điện thoại">
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem
            name="email"
            label="Email"
            rules={[
              {
                pattern: {
                  value: REGEX.EMAIL,
                  message: messageValidate.email,
                },
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem name="website" label="Website">
            <Input placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem name="is_active" label="Trạng thái hoạt động">
            <Select
              placeholder="Vui lòng chọn"
              options={optionsStoreStatus}
              allowClear={false}
            />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default FormStore;
